import Button from '@/components/base/elements/Button/Button';
import Typography from '@/components/base/elements/Typography/Typography';
import {useTranslations} from '@/hooks/useTranslations';

export default function BlogRoundedBanner() {
  const {t} = useTranslations();

  const {heading, subhead, button} = t('hero');
  const buttonProps = {
    className: 'bg-[#69E075] whitespace-nowrap',
    mode: 'dark' as 'light' | 'dark',
    ...button,
  };

  return (
    <div className="desktop:container desktop:px-0 px-4 py-2.5 md:px-20 md:py-8">
      <div className="bg-black py-2.5 pl-8 pr-4 rounded-full flex items-center justify-between gap-2 md:py-6 md:pl-9 md:pr-6">
        <div className="md:pl-3">
          <Typography
            as="p"
            size="body-lg"
            className="text-white font-aktivgroteskextended wrap-balance leading-[120%] font-semibold tracking-normal"
          >
            {heading}
          </Typography>
        </div>
        <div className="hidden md:block">
          <Typography
            as="p"
            size="body-base"
            className="text-white font-normal wrap-balance"
          >
            {subhead}
          </Typography>
        </div>
        <div className="flex">
          <Button {...buttonProps}>{button.text}</Button>
        </div>
      </div>
    </div>
  );
}
