import {EXP_GUSTILY_SOLLAR_GABS} from '@/experiments';

import {useExperiment} from '../useExperiment';

export const useGustilySollarGabsExp = () => {
  const isConversionBannerVariant =
    'control' === useExperiment(EXP_GUSTILY_SOLLAR_GABS);
  const isRoudedBannerVariant =
    'treatment' === useExperiment(EXP_GUSTILY_SOLLAR_GABS);
  return {
    isConversionBannerVariant,
    isRoudedBannerVariant,
  };
};
