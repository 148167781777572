import {useLoaderData} from '@remix-run/react';
import {type LinksFunction} from '@remix-run/server-runtime';

import BlogArticle from '@/components/blog/Article/Article';
import PageLayout from '@/components/shared/Page/PageLayout';
import {EN_BLOG_PATH} from '@/components/blog/constants';
import {BlogSubNav} from '@/components/pages/blog/BlogSubNav/BlogSubNav';
import Footer from '@/components/brochureV2/section/Footer/Footer';
import {ColorSchemeEnum, Theme} from '@/enums';
import type {Handle} from '@/types';
import {TopScrollbar} from '@/components/blog/TopScrollbar/TopScrollbar';
import {useSharedTranslations} from '@/hooks/useTranslations';
import {useSiteData} from '@/hooks/useSiteData';
import LatestSection from '@/components/pages/blog/LatestSection/LatestSection';
import {BlogConversion} from '@/components/blog/BlogConversion/BlogConversion';
import blogStylesheet from '@/stylesheets/blog/blog.css?url';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import BlogRoundedBanner from '@/components/blog/BlogRoundedBanner/BlogRoundedBanner';
import useRoundedBannerRender from '@/components/blog/hooks/useRoundedBannerRender';
import BlogArticleHeader from '@/components/blog/BlogArticleHeader/BlogArticleHeader';
import {useBlogPageTitle} from '@/hooks/blog/useBlogPageTitle';
import {getArticleYear, getPopularPosts} from '@/utils/blog/article';

export const links: LinksFunction = () => {
  return [{rel: 'stylesheet', href: blogStylesheet}];
};

export const handle: Handle = {
  // Disable automatic hreflang generation because there is no guarantee that
  // a blog is published in all locales. The only assumption that is safe to
  // make is that if the article is published on an English site, then it is
  // also published on all other English sites except for the Indian site.
  // With the exception of the Indian blog site, the English blog sites all use
  // the same Storefront to store articles; that is they are mirrored. The
  // Indian blog site uses a different Storefront.
  manualHrefLangs: true,
  theme: Theme.Blog,
};

export {blogMeta as meta} from '@/pages/shopify.com/($locale)/blog/meta';

export {articleLoader as loader} from '../../../../../utils/blog/articleLoader.server';

export default function Article() {
  const {
    settings,
    latestArticles,
    article,
    contentHtml,
    contentNavigationLinks,
    availableRegions,
    metaDescription,
    subNavVariant,
  } = useLoaderData<any>() ?? {};
  const {t} = useSharedTranslations('pages/blog/$article/content');

  const {site} = useSiteData();

  const popularPosts = getPopularPosts(settings, site, article);
  const latestPosts = latestArticles.slice(0, 8);

  let metaTitle = article?.seo?.title || article?.title;
  const metaTitleYear = getArticleYear(metaTitle, article?.modifiedAt);

  if (
    metaTitleYear &&
    !metaTitle.includes(metaTitleYear) &&
    !metaTitle.includes(metaTitleYear + 1)
  ) {
    metaTitle = t('articleMetaTitle', {
      title: metaTitle,
      year: metaTitleYear,
    });
  }

  const availableHreflangs = availableRegions && new Set([...availableRegions]);

  //This is a lo-fi experiment requested by product (no top banner in en-PH). It will be removed in the future
  const isRoudedBannerVariant = useRoundedBannerRender(site);
  const header = (): any => {
    if (site.locale === 'en-PH') return null;
    return isRoudedBannerVariant ? (
      <BlogRoundedBanner />
    ) : (
      <BlogArticleHeader blogType={BLOG_PAGE_TYPE.BLOG} />
    );
  };

  const pageTitle = useBlogPageTitle(metaTitle);

  return (
    <PageLayout
      title={pageTitle}
      metaDescription={metaDescription}
      header={(props) => (
        <BlogSubNav
          {...props}
          subNavVariant={subNavVariant}
          enBlogPath={EN_BLOG_PATH.CORE}
          blogPageType={BLOG_PAGE_TYPE.BLOG}
        />
      )}
      footer={
        <Footer
          colorScheme={ColorSchemeEnum.Dark}
          availableHreflangs={availableHreflangs}
        />
      }
      isPureTitle
    >
      <TopScrollbar blogPageType={BLOG_PAGE_TYPE.BLOG} />
      <BlogArticle
        article={article}
        enBlogPath={EN_BLOG_PATH.CORE}
        popularPosts={popularPosts}
        articleContentHtml={contentHtml}
        contentNavigationLinks={contentNavigationLinks}
        blogLinkText={t('articleHeader.blogLink.text')}
        settings={settings}
        blogPageType={BLOG_PAGE_TYPE.BLOG}
        header={header()}
      />
      {latestPosts.length > 0 && (
        <LatestSection
          sectionHead={t('latestArticles')}
          primaryArticles={latestPosts}
          enBlogPath={EN_BLOG_PATH.CORE}
        />
      )}
      <BlogConversion
        heading={t('sellAnywhere.headingHtml')}
        subhead={t('sellAnywhere.subheadHtml')}
        buttonHref={t('sellAnywhere.button.href')}
        buttonText={t('sellAnywhere.button.text')}
        disclaimer={t('sellAnywhere.disclaimerHtml')}
        mode="green"
      />
    </PageLayout>
  );
}
