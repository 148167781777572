import {EXP_LEARN_MORE_PRICING_HEADER_ALL_EN_LOCALES} from '@/experiments';
import {useTranslations} from '@/components/shared/Page/I18N';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';

import {useExperiment} from '../useExperiment';
import {useSiteData} from '../useSiteData';

export const ALLOWED_LOCALES = ['en', 'en-AU', 'en-CA', 'en-GB'];

export const usePricingCta = (
  blogType: BLOG_PAGE_TYPE,
): {text: string; href: string} | undefined => {
  const {t, localizePath} = useTranslations();
  const {site} = useSiteData();

  const isCoreBlog = blogType === BLOG_PAGE_TYPE.BLOG;

  const expValue = useExperiment(EXP_LEARN_MORE_PRICING_HEADER_ALL_EN_LOCALES);

  if (!isCoreBlog || !ALLOWED_LOCALES.includes(site.locale) || !expValue) {
    return;
  }

  switch (expValue) {
    case 'control':
      return {
        text: t('hero.lightButton.text'),
        href: localizePath('/'),
      };
    case 'treatment':
      return {
        text: t('hero.lightButton_exp.home'),
        href: localizePath('/'),
      };
    case 'treatment_2':
      return {
        text: t('hero.lightButton_exp.whatIs'),
        href: localizePath('/blog/what-is-shopify'),
      };
    case 'treatment_3':
      return {
        text: t('hero.lightButton_exp.pricing'),
        href: localizePath('/pricing'),
      };
  }
};
