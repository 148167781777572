import type {Site} from '@data/types';
import {useGustilySollarGabsExp} from '@/hooks/experiments/useGustilySollarGabsExp';

export default function useRoundedBannerRender(site: Site): boolean {
  const {isRoudedBannerVariant} = useGustilySollarGabsExp();

  return (
    isRoudedBannerVariant && ['en-AU', 'en-GB', 'en-CA'].includes(site.locale)
  );
}
